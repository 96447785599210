<template>
  <router-view/>
</template>
<script >
import * as echarts from 'echarts'
import { provide } from 'vue'
 
export default {
  name: 'App',
  setup(){
    provide('echarts',echarts)               //provide
  },
  components: {
  }
}
</script>
<style lang="scss">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    color: #2c3e50;
  }
  html,body,div,p,span,ul,li,input{
    margin: 0;
    padding: 0;
  }
  li{
    list-style: none;
  }
  html,body,#app{
    width: 100%;
    height: 100%;
  }
  .flex1{
    flex: 1;
  }
  .vertic{
    vertical-align: middle;
  }
  .text_cent{
    text-align: center
  }
  a:visited, a:hover, a:active, a:link{
    color: inherit;
    text-decoration: none;
  }

  .pro_dia .el-dialog__body{
    display: flex;
  }
</style>
