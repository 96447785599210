import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/manager/Home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/manager/About.vue')
  },
  {
    path: '/:pathMatch(.*)',
    name: '404',
    component: () => import(/* webpackChunkName: "about" */ '../views/manager/About.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/manager/login.vue')
  },
  {
    path: '/config',
    name: 'config',
    component: () => import('../components/config.vue'),
    children: [
      {
        path: '/Home',
        name: 'Home',
        component: () => import('../views/manager/Home.vue')
      },
      {
        path: '/statistics',
        name: 'statistics',
        component: () => import('../views/manager/statistics.vue')
      },
      {
        path: '/country',
        name: 'country',
        component: () => import('../views/manager/country.vue')
      },
      {
        path: '/roleManager',
        name: 'roleManager',
        component: () => import('../views/manager/roleManager.vue')
      },
      {
        path: '/menuManager',
        name: 'menuManager',
        component: () => import('../views/manager/menuManager.vue')
      },
      {
        path: '/persionManager',
        name: 'persionManager',
        component: () => import('../views/manager/persionManager.vue')
      },
      {
        path: '/originManager',
        name: 'originManager',
        component: () => import('../views/manager/originManager.vue')
      },
      {
        path: '/dischargeType',
        name: 'dischargeType',
        component: () => import('../views/manager/dischargeType.vue')
      },
      {
        path: '/taskManager',
        name: 'taskManager',
        component: () => import('../views/manager/taskManager.vue')
      },
      {
        path: '/typeManager',
        name: 'typeManager',
        component: () => import('../views/manager/typeManager.vue')
      },
      {
        path: '/factorManager',
        name: 'factorManager',
        component: () => import('../views/manager/factorManager.vue')
      },
      {
        path: '/about',
        name: 'About',
        component: () => import('../views/manager/About.vue')
      },
      {
        path: '/carbonBox',
        name: 'carbonBox',
        component: () => import('../views/manager/carbonBox.vue')
      },
      {
        path: '/counterInfo',
        name: 'counterInfo',
        component: () => import('../views/manager/counterInfo.vue')
      },
      {
        path: '/industryInfo',
        name: 'industryInfo',
        component: () => import('../views/manager/industryInfo.vue')
      },
      {
        path: '/pepeoDate',
        name: 'pepeoDate',
        component: () => import('../views/manager/pepeoDate.vue')
      },
      {
        path: '/TTHDate',
        name: 'TTHDate',
        component: () => import('../views/manager/TTHDate.vue')
      },
      {
        path: '/CLCDate',
        name: 'CLCDate',
        component: () => import('../views/manager/CLCDate.vue')
      },
      {
        path: '/loginLog',
        name: 'loginLog',
        component: () => import('../views/manager/loginLog.vue')
      },
      {
        path: '/loginCode',
        name: 'loginCode',
        component: () => import('../views/manager/loginCode.vue')
      },
      {
        path: '/ELCDate',
        name: 'ELCDate',
        component: () => import('../views/manager/ELCDate.vue')
      },
      {
        path: '/ECOIVENTDate',
        name: 'ECOIVENTDate',
        component: () => import('../views/manager/ECOIVENTDate.vue')
      },
      {
        path: '/LCXDate',
        name: 'LCXDate',
        component: () => import('../views/manager/LCXDate.vue')
      },
      {
        path: '/dateBase',
        name: 'dateBase',
        component: () => import('../views/manager/dateBase.vue')
      },
      {
        path: '/moreDateBase',
        name: 'moreDateBase',
        component: () => import('../views/manager/moreDateBase.vue')
      },
      {
        path: '/dateBaseManager',
        name: 'dateBaseManager',
        component: () => import('../views/manager/dateBaseManager.vue')
      },
      {
        path: '/factorMX',
        name: 'factorMX',
        component: () => import('../views/manager/factorMX.vue')
      },
      {
        path: '/dateRepate',
        name: 'dateRepate',
        component: () => import('../views/manager/dateRepate.vue')
      },
      {
        path: '/allCount',
        name: 'allCount',
        component: () => import('../views/manager/allCount.vue')
      },
      {
        path: '/checkFuns',
        name: 'checkFuns',
        component: () => import('../views/manager/checkFun.vue')
      },
      {
        path: '/CFoot',
        name: 'CFoot',
        component: () => import('../views/manager/CFoot.vue')
      }
    ]
  },
  //用户端
  {
    path: '/userLogin',
    name: 'userLogin',
    component: () => import('../views/user/login.vue')
  },
  {
    path: '/userConfigs',
    name: 'userConfigs',
    component: () => import('../components/userConfignew.vue'),
    children: [
      {
        path: '/checkFun',
        name: 'checkFun',
        component: () => import('../views/user/checkFun.vue')
      },
      {
        path: '/origaniDefin',
        name: 'origaniDefin',
        component: () => import('../views/user/origaniDefin.vue')
      },
      {
        path: '/carbCalcu',
        name: 'carbCalcu',
        component: () => import('../views/user/carbCalcu.vue')
      },
      {
        path: '/carbResult',
        name: 'carbResult',
        component: () => import('../views/user/carbResult.vue')
      },
      {
        path: '/dataCount',
        name: 'dataCount',
        component: () => import('../views/user/dataCount.vue')
      },
      {
        path: '/noPage',
        name: 'noPage',
        component: () => import('../views/user/noPage.vue')
      },
      {
        path: '/userInfo',
        name: 'userInfo',
        component: () => import('../views/user/userInfo.vue')
      },
      {
        path: '/analySee',
        name: 'analySee',
        component: () => import('../views/user/analySee.vue')
      },
      {
        path: '/organiCalcu',
        name: 'organiCalcu',
        component: () => import('../views/user/organiCalcu.vue')
      },
      {
        path: '/carbonOffset',
        name: 'carbonOffset',
        component: () => import('../views/user/carbonOffset.vue')
      },
      {
        path: '/others',
        name: 'others',
        component: () => import('../views/user/others.vue')
      },
      {
        path: '/reportManager',
        name: 'reportManager',
        component: () => import('../views/user/reportManager.vue')
      },
      {
        path: '/addtabs',
        name: 'addtabs',
        component: () => import('../views/user/addtabs.vue')
      },
      {
        path: '/productCarbon',
        name: 'productCarbon',
        component: () => import('../views/user/productCarbon.vue')
      },
      {
        path: '/preCarbon',
        name: 'preCarbon',
        component: () => import('../views/user/preCarbon.vue')
      }
      
    ]
  },
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
